<template>
  <Loader :value="promises.fetch" class="post">
    <component v-if="component" :is="component" :item="post" :project="item" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Types from './Types'
import BrowserApiMixin from '@/mixins/browser-api'

export default {
  mixins: [BrowserApiMixin('post')],
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    post() {
      if (!this.item.posts) return {}
      return this.item.posts.find(p => p.slug === this.slug)
    },
    component() {
      const sources = this.post?.customFieldsConfig?.map(schema => schema.source) || []
      switch (sources[0]) {
        default:
          return Types.Subpage
      }
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    ...Types,
  },
}
</script>

<style lang="scss">
.post {
}
</style>
